// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 1,
		title: 'Le marché des actions européen',
		category: 'Droit financière/Union Européenne',
		img: require('@/assets/images/ar-1-img-1.jpg'),
	},
	{
		id: 2,
		title: 'Législation financière de Bruxelles',
		category: 'Droit financière/Union Européenne',
		img: require('@/assets/images/ar-2-img-1.jpg'),
	},
	{
		id: 3,
		title: 'Aperçu du droit financière en Europe',
		category: 'Droit financière/Union Européenne',
		img: require('@/assets/images/ar-3-img-1.jpg'),
	},
	{
		id: 4,
		title: 'La faillite de la Silicon Valley Bank',
		category: 'Événements bancaires',
		img: require('@/assets/images/ar-4-img-3.png'),
	},
	{
		id: 5,
		title: 'Un système bancaire utopique',
		category: 'Concepts et idées',
		img: require('@/assets/images/ar-5-img-1.png'),
	},
	{
		id: 6,
		title: 'Pourquoi Credit Suisse a-t-elle échoué?',
		category: 'Événements bancaires',
		img: require('@/assets/images/ar-6-img-1.png'),
	},
];

export default projects;
