export const socialLinks = [
	{
		id: 1,
		name: 'Website',
		icon: 'globe',
		url: 'https://www.lebureaududroit.com/',
	},
	{
		id: 2,
		name: 'LinkedIn',
		icon: 'linkedin',
		url: 'https://www.linkedin.com/in/alexandru-popescu-269a58255',
	},
	
];
